.Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Contact-center{
    background: #000000;
    box-shadow: 0px 0px 29px 0px #000000;
    border-radius: 2px;
    width: calc(530px + 10vw);
    max-width: calc(100vw - 40px);
    height: calc(180px + 10vh);
    margin-right: 10vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.Contact-title{
    width: 196px;
    height: 56px;
    font-size: 40px;
    font-family: 'PingFangSC-Semibold', 'PingFang SC';
    font-weight: 600;
    color: #FFFFFF;
    line-height: 56px;
}

.Contact-info1 {
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    background: #181818;
    border-radius: 2px;
    font-size: 13pt;
    color: #FFFFFF;
}

.Contact-info2{
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    background: #181818;
    border-radius: 2px;
    font-size: 13pt;
    color: #FFFFFF;
}