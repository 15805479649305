@import '~antd/dist/antd.css';
@import './customized_steps.css';
@import './Contact.css';

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: #0c0c0c;
  width: 100%;
  height: 100%;
  overflow: scroll;
  scroll-snap-type: y mandatory;
}

.mobile .Home {
  padding-right:16px;
}

.Section,
.Section_last {
  scroll-snap-align: start;
}

.App-logo {
  width: 15vw;
  min-width: 100px;
  pointer-events: none;
  z-index: 1;
}

.mobile .App-logo {
  width: 20vw;
  min-width: initial;
}

.App-link {
  color: #61dafb;
}

.App-left {
  position: fixed;
  left: 0px;
  width: 20vw;
  min-width: 90px;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 3vw;
  top: -1vw;
}

.Section {
  padding-left: 20vw;
  padding-right: 5vw;
  /* background-color: #ffffff33; */
  width: 100vw;
  height: 100vh;
  padding-top: 8vh;
  border-top: 1px solid gray;
}

.Section_last {
  width: 100vw;
  height: 100vh;
  border-top: 1px solid gray;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  /*background:url('./static/logos/Contact_bg@2x.png') ;*/
  background-size: cover;
}

.Section_last__content {
  padding-left: 20vw;
  padding-right: 5vw;
  border-top: 1px solid gray;
  flex: 1;
}

footer {
  background-color: #252525;
  min-height: 5vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Portfolios-center > div {
  margin: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: calc(5px + 0.2vw);
}

.Portfolios img {
  margin-bottom: 0.8em;
  width: calc(10vmin);
  height: calc(10vmin);
  /*border: 1px dashed white;*/
  border-radius: 200px;
}

.Portfolios {
  /*background-image: url('./static/Portfolios_bg@2x.png');*/
  /*background-size: cover;*/
}

.Portfolios-center {
  display: flex;
  flex-flow: row wrap;
}

.Intro {
  color: white !important;
}

.Intro-h {
  font-size: calc(15px + 1.5vw);
  font-family: 'PingFangSC-Semibold', 'PingFang SC';
  font-weight: 600;
  color: #ffffff;
  line-height: calc(15px + 1.5vw);
  letter-spacing: 5px;
  margin-right: 10vw;
  margin-bottom: 3vw;
}

.Intro-p {
  /*width: calc(500px + 25vw);*/
  margin-right: 10vw;
  font-size: calc(10px + 0.7vw) !important;
  font-family: 'PingFangSC-Regular', 'PingFang SC';
  font-weight: 400;
  color: #ffffff;
  line-height: calc(16px + 1vw);
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
}

.mobile .Intro-p {
  word-break: break-all;
  margin-right: 0;
  font-size:12px!important;
  line-height: 18px!important;
}

.Intro-img-container {
  position: relative;
  z-index: 1;
}

.Intro-img {
  position: absolute;
  width: 15vw;
  right: 15vw;
  top: -10vh;
  z-index: 1;
}

.Home-h {
  width: 32.125vw;
  min-width: 32.125vw;
  height: 28.729vw;
  font-size: 5.116vw;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #ffffff !important;
  line-height: 12.5vh;
  text-align: left;
  margin-top: 7.204vw;

  position: relative;
  z-index: 2;
}

.Home-h-mobile {
  width: 32.125vw;
  min-width: 32.125vw;
  height: 28.729vw;
  font-size: 5.116vw;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #ffffff !important;
  text-align: left;
  margin-top: 7.204vw;

  position: relative;
  z-index: 2;
}
.Home-hr {
  margin-top: calc(50px + 25vh - 15vw); /* fit for more sizes of screen */
  width: 76vw;
  height: 3px;
  background: #252525;
}

.Home-hr-block {
  width: 60px;
  height: 10px;
  background: #0f33ff;
}

.Home-horizontal {
  display: flex;
  flex-direction: row;
}

.Home-img {
  width: 40.3vw;
  position: relative;
  left: -4.42vw;
  top: calc(50px + 25vh - 15vw); /* fit for more sizes of screen */
  z-index: 1;
}

.value-add {
  margin-top: 0.3rem;
}

.s-wrapper {
  margin-right: 0.78rem;
  text-align: center;
  /* width: 25vw; */
  font-size: 400;
  margin-bottom: 3rem;
}

.Margin_box {
  margin-top: 6rem;
}

.section-title {
  font-size: 1.7rem;
  font-family: 'PingFangSC-Regular', 'PingFang SC';
  font-weight: 700;
  color: white;
  line-height: 0.24rem;
  margin-bottom: 1rem;
}

.s-wrapper p {
  font-size: calc(10px + 0.7vw) !important;
  font-family: 'PingFangSC-Regular', 'PingFang SC';
  font-weight: 400;
  color: white;
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
  /* text-indent:3rem; */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.65rem;
  padding: 0 0.6rem;
  top: -5vh;
}

.header-mobile {
  /*width: 32.125vw;
  min-width: 32.125vw;
  height: 28.729vw;
  font-size: 5.116vw;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #ffffff !important;
  text-align: left;
  margin-top: 7.204vw;

  position: relative;
  z-index: 2;*/

  margin-top: -3.2rem;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header ul li {
  margin-right: 5rem;
}

.header-mobile ul li {
  margin-right: 12px;
}

.header-mobile ul {
  padding-left: 0.5rem;
  flex: 1;
}

.header-mobile ul li::marker {
  display: none;
}

.header ul li img {
  max-height: 2rem;
}

.header-mobile ul li img {
  max-height: 1.2rem;
}

.header ul li a {
  color: white;
  font-weight: 400;
  font-size: 20px;
}

.header-mobile ul li a {
  font-size: 3vw;
}
.mobile .Intro-h{
  margin-right: 0;
}